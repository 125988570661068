import { BehaviorSubject } from "rxjs";
import { ApiUrl } from "../../Helpers/ApiUrl";
import { Method } from "../../Helpers/Method";
import Cookie from "universal-cookie";
import { Environment } from "../../Environment/Settings";
const cookie = new Cookie();
const CurrentUserSubject = new BehaviorSubject(cookie.get('current_user'));
const CurrentTokenSubject = new BehaviorSubject(cookie.get('access_token'));
const CurrentPermissionSubject = new BehaviorSubject(cookie.get('permissions'));
var options = "";
export const AuthService = {
  Login,
  Logout,
  GetData,
  setCookies,
  Cookies,
  CurrentToken: CurrentTokenSubject.asObservable(),
  CurrentUser: CurrentUserSubject.asObservable(),
  Permission: CurrentPermissionSubject.asObservable(),
  get CurrentUserValue() {
    return CurrentUserSubject.value;
  },
  get CurrentTokenValue() {
    return CurrentTokenSubject.value;
  },
  get CurrentPermissionValue() {
    return CurrentPermissionSubject.value;
  }
};

function Login(email, password) {
  const res = Method.Post(ApiUrl.Signin, { email, password }).then(
    (data) => {
      if (data.data !== null && data.data !== undefined && data.data.token !== null) {
        Cookies(data);
      }
      else {
        return data;
      }
      return data;
    }
  );
  return res;
}

function Cookies(data) {
  options = { path: "/", domain: Environment.Sub_Domain };
  setCookies("access_token", data.data.token, options);
  setCookies("permissions", data.data.permissions, options);
  setCookies("current_user", JSON.stringify(data.data.user), options);
  setCookies("portal", JSON.stringify(data.data.menus), options);
  CurrentUserSubjectValues(data.data);
}

function setCookies(key, value, options) {
  cookie.set(key, value, options);
}

function CurrentUserSubjectValues(value) {
  CurrentUserSubject.next(value.user);
  CurrentTokenSubject.next(value.token);
  CurrentPermissionSubject.next(value.permissions);
}
function Logout() {
  // remove user from cookie to log user out

  options = { path: "/", domain: Environment.Sub_Domain };
  cookie.remove("access_token", options);
  cookie.remove("current_user", options);
  cookie.remove("permissions", options);
  cookie.remove("portal", options);
  cookie.remove("menus", options);
  if (CurrentUserSubject._value != null) {
    CurrentUserSubject.next(null);
  }
  if (CurrentTokenSubject._value != null) {
    CurrentTokenSubject.next(null);
  }
  if (CurrentPermissionSubject._value != null) {
    CurrentPermissionSubject.next(null);
  }
}

function GetData() {
  const res = Method.Get(ApiUrl.GetData).then(data => {
    return data;
  });
  return res;
}
