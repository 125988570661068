import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import "./Login.css"
import { useForm } from 'react-hook-form';
import { Validation } from '../../../../Helpers/Validation';
import { Message } from '../../../../Helpers/Message';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import { UserService } from '../../../../Services/User/UserService';
export const ConfirmAccountModal = () => {

    const [isLoadingEmail, setIsLoadingEmail] = useState(false);
    const [Show, setShow] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const onSubmits = handleSubmit((data) => {


        setIsLoadingEmail(true);
        var email = data.emails;
        UserService.ConfirmAccountEmail({ email }).then(data => {

            setIsLoadingEmail(false);
            if (!data.isValidated) {
                toast.error(data.message, {
                });
            }
            else {
                makeRequest(data);
                setShow(false);
            }

        });
    });
    async function makeRequest(data) {
        await delay(1000);
        toast.success(data.message, {
        });

    }

    function closeClick() {

        setShow(false);

    }
    return (

        <Modal
            size="lg"
            show={Show}
            className="helm-modal"
            onHide={closeClick}
            aria-labelledby="contained-modal-title-vcenter "
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Enter Email
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ToastContainer closeOnClick={true} />
                <form onSubmit={onSubmits}>
                    <div className='login-page'>
                        <div className='enter-form'>
                            <div className='row'>
                                <label>Email *</label>
                                <div className="col-md-12">
                                    <input type="text" name="emails" className={"w-100"} autoComplete="nope" {...register("emails", {
                                        required: "Required", pattern: {
                                            value: Validation.ValidateEmail,
                                            message: Message.ValidEmail
                                        }
                                    })} placeholder={"Enter email"} />
                                    <span className='text-danger'>{errors?.emails && errors.emails.message}</span>
                                </div>

                            </div>
                        </div>

                        <div className='row mt-3 modal-footer'>
                            <div className='col-lg-12 col-md-12 col-12 d-flex justify-content-end'>
                                <button className="btn btn-secondary me-3" onClick={closeClick}>
                                    <span>Close</span>
                                </button>
                                {/* <FormButton className={'btn btn-success'} IsLoading={isLoadingEmail} ButtonText="Submit" icon={false} /> */}
                                <button type='submit' className="btn btn-success">
                                    {isLoadingEmail && <span>
                                        <span className="spinner-border spinner-border-sm  me-1" role="status" aria-hidden="true"></span>
                                        Processing...
                                    </span>}

                                    {!isLoadingEmail &&
                                        <span>Submit</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>

        </Modal>
    )
}
