import { Navigate } from "react-router-dom";
import { AuthService } from './Auth/AuthService';
import { useLocation } from "react-router-dom";
import { AppUrl } from '../Helpers/AppUrl';
export const UnProtectedRoute = ({ children }) => {
    const search = useLocation().search;
    var user = AuthService.CurrentUserValue;
    var token = AuthService.CurrentTokenValue;
    if (user !== null && user !== undefined && user !== "null" && user !== "undefined" && token !== null && token !== undefined && token !== "null" && token !== "undefined") {

        var url = new URLSearchParams(search).get("helm");
        if (url != null && url !== "") {
            var path = url.split("/", 2);
            if (path[0] === AppUrl.VesselDetail) {
                //navigate("/" + url);
                return <Navigate to={"/" + url} />;
            }
        }
        return <Navigate to="/Home" />;
    }
    return children;
};