export default function FormInputDiv({ row, col, type, register, value, req, setFocus, inputClass, placeholder, name, error, requiredText, requiredValid, ValidMessage, onChange }) {

    return (
        <>
            <div className={col}>
                <input type={type} className={inputClass} autoComplete="nope" {...register(name, {
                    required: requiredText, pattern: {
                        value: requiredValid,
                        message: ValidMessage
                    }
                })} onChange={onChange} name={name} value={value} placeholder={placeholder} />
                <br />
                <span className='text-danger'> {error}</span>
            </div>
        </>

    );
}