import React from "react";
import { NavLink } from "react-router-dom";
import { ImageUrl } from "../../../../Helpers/ImageUrl";
import { AuthService } from "../../../../Services/Auth/AuthService";
import { useNavigate } from "react-router-dom";
import { AppUrl } from "../../../../Helpers/AppUrl";
import UserLogoTwo from '../../../Shared/Images/user-logo-two.svg';
import lottie from "lottie-web";
import message from './lf30_editor_mndcwvyb.json';
import call from './lf30_editor_xm9jiphh.json';
import { Dropdown } from "react-bootstrap";
import Cookie from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./NavStyle.css";
import { Message } from "../../../../Helpers/Message";

const cookie = new Cookie();
export default function Navbar() {
  let navigate = useNavigate();
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  function logout() {
    AuthService.Logout();
    toast.success(Message.LoggedOut, {
    });
    makeRequest()
  }
  async function makeRequest() {

    await delay(1000);
    navigate("/");
  }
  function Profile() {
    navigate(AppUrl.UpdateProfile);
  }
  function Vessel() {
    navigate(AppUrl.VesselManagement);
  }
  function ChangePassword() {
    navigate(AppUrl.ChangePassword);
  }
  const menulisting = cookie.get('portal') === "undefined"
    ? ''
    : cookie.get('portal');

  const userImage = cookie.get('current_user') === 'undefined'
    ? ''
    : cookie.get('current_user');
  if (userImage !== undefined)
    userImage.imageUrl = userImage?.imageUrl == null ? UserLogoTwo : userImage?.imageUrl;

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#call"),
      animationData: call,
    });
  }, []);

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#message"),
      animationData: message,
    });
  }, []);

  return (
    <div>
      {AuthService !== null && AuthService.CurrentUserValue ? (
        <header className="header">
          <a className="header-contact-btn" href={AppUrl.ContactUs}>Contact Us</a>
          <ToastContainer closeOnClick={true} />
          <div className="header-one">
            <div className="container-width container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-8">
                  <div className="header-one-area-content d-flex align-items-center h-100">
                    <a className="contact-icons d-flex align-items-center" href="tel:+1 561 257 0404">
                      <div id="call" className="icon-holder head-icons">
                      </div>
                      <h6 className="mb-0">+1 561 257 0404</h6>
                    </a>
                    <a className="contact-icons d-flex align-items-center contact-icons-margin" href="mailto:support@helm-track.com">

                      <div id="message" className="icon-holder head-icons">
                      </div>
                      <h6 className="mb-0">support@helm-track.com</h6>
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-sm-4">
                  <ul className="navbar-nav navbar-nav-about">
                    <li className="nav-item">
                      <a className="nav-link" href="about">
                        ABOUT
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="header-two">
            <div className="container-width container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <NavLink to={AppUrl.Home} className="header-two-images">
                    <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                  </NavLink>
                </div>
                <div className="col-md-9">
                  <div className="header-two-images d-flex justify-content-end">
                    <iframe title="header" src="https://services.marinelink.com/banman/a.aspx?ZoneID=212&amp;Task=Get&amp;PageID=72153&amp;SiteID=28" width={728} height={90} marginWidth={0} marginHeight={0} hspace={0} vspace={0} frameBorder={0} scrolling="No">
                      <script language='javascript1.1' src="https://services.marinelink.com/banman/a.aspx?ZoneID=212&amp;Task=Get&amp;Browser=NETSCAPE4&amp;NoCache=True&PageID=72153&amp;SiteID=28"></script>
                      <noscript><a href="https://services.marinelink.com/banman/a.aspx?ZoneID=212&amp;Task=Click&amp;Mode=HTML&amp;SiteID=28&amp;PageID=72153" target="_blank" rel="noreferrer">
                        <image src="https://services.marinelink.com/banman/a.aspx?ZoneID=212&amp;Task=Get&amp;Mode=HTML&amp;SiteID=28&amp;PageID=72153" width="728" height="90" border="0" style="max-width:100%; height:auto;" alt="" /></a>
                      </noscript>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-three">
            <nav className="navbar navbar-expand-lg navbar-light navbar-bg p-0">
              <div className="container-fluid container-width">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <i className="navbar-toggler-icon toggler-icon d-flex align-items-center" aria-hidden="true"></i>
                </button>
                <div className="collapse navbar-collapse header-list justify-content-between" id="navbarSupportedContent">
                  <ul className="navbar-nav mb-2 mb-lg-0">

                    {menulisting?.map((index) => (

                      <li className="nav-item" key={index.id}>
                        <NavLink replace to={index.url}
                          className={({ isActive }) =>
                            isActive ? "nav-link active" : "nav-link"
                          }>
                          {index.name}
                          <div className="icon-holder">
                            <lottie-player loop
                              src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                            ></lottie-player>
                          </div>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                  <Dropdown className="imagesize">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <div className="profileImage">
                        <img className="hover img-fluid rounded-circle" alt="UserLogoOne" src={userImage?.imageUrl}
                          onError={event => {
                            event.target.src = UserLogoTwo
                            event.onerror = null
                          }}
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={Profile}>Profile</Dropdown.Item>
                      {userImage?.role === 'Company' && <Dropdown.Item onClick={Vessel}>Vessel Management</Dropdown.Item>
                      }
                      {userImage?.isGmail === false && <Dropdown.Item onClick={ChangePassword}>Change Password</Dropdown.Item>
                      }
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </nav>
          </div>
        </header >
      ) : (
        <div></div>
      )
      }
    </div >
  );
}

