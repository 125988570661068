import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import FormInputDiv from '../../../Shared/Layout/Custom/FormInputDiv';
import FormInputCp from '../../../Shared/Layout/Custom/FormInputCp';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import { ProfileService } from '../../../../Services/Profile/ProfileService';
import { Message } from '../../../../Helpers/Message';
import { Validation } from '../../../../Helpers/Validation';
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function UpdatePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [metaTag, setMetaTag] = useState('');
  const [title, setTitle] = useState('');
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({ mode: "all" });
  const password = useRef({});
  password.current = watch("password", "");
  useEffect(() => {
    setMetaTag(Message.Description);
    setTitle(Message.Title);
  }, []);
  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    ProfileService.UpdatePassword(data).then(data => {
      setIsLoading(false);

      toast.success(data.message, {
      });

      reset({
        currentPassword: "", password: "", cPassword: ""
      })
    });
  })
  return (
    <>
      <ToastContainer closeOnClick={true} />
      <Helmet>
        <title>Helm-Track</title>
        <meta name="title" content={title} />
        <meta name="description" content={metaTag} />
      </Helmet>
      <div className="container mt-5 update-page">
        <form onSubmit={onSubmit}>
          <div className="input-wrapper common-form-wraper mb-3">
            <label className="p-heading-lable label" htmlFor="first">Change Password</label>
            <div className="border-field ">
              <div className="row d-flex justify-content-center">
                <div className='col-lg-6 col-md-6 col-6'>
                  <FormInputDiv register={register} inputClass={" w-100"} name="currentPassword"
                    error={errors.currentPassword?.message} type={'Password'} requiredText={Message.RequiredField}
                    placeholder={"Current password"}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-6 col-6 mt-3">
                  <FormInputDiv register={register} inputClass={" w-100"} name="password"
                    error={errors.password?.message} type={'Password'} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                    placeholder={"Enter Password"}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-6 col-6 mt-3">
                  <FormInputCp register={register} inputClass={" w-100"} name="cPassword"
                    error={errors.cPassword?.message} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                    placeholder={"Re-enter password"} validConfirmPassword={Message.ValidConfirmPassword} watch={watch}
                    type={'Password'}
                  />
                </div>
              </div>
              <div className="row pt-3 d-flex justify-content-end filter-buttons">
                <div className="col-12 d-flex justify-content-end">
                  <FormButton className={'btn btn-success'} IsLoading={isLoading} ButtonText="Change Password" icon={false} width={true} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
