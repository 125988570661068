/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import "./Login.css"
import "../../../Shared/css/style.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { AuthService } from '../../../../Services/Auth/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import { Validation } from '../../../../Helpers/Validation';
import { Message } from '../../../../Helpers/Message';
import GoogleAuth from '../../../Shared/Layout/Custom/Button/GoogleAuth/GoogleAuth';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import { Environment } from '../../../../Environment/Settings';
import LoginImg from '../../../Shared/Images/login-img.png';
import { ImageUrl } from "../../../../Helpers/ImageUrl";
import { Helmet } from "react-helmet";
import { AppUrl } from '../../../../Helpers/AppUrl';
import { useLocation } from "react-router-dom";
import { ConfirmAccountModal } from './ConfirmAccountModal';

const Login = () => {

  const search = useLocation().search;
  const [isLoading, setIsLoading] = useState(false);
  const [metaTag, setMetaTag] = useState('');
  const [title, setTitle] = useState('');
  const [bootstrapModal, setBootstrapModal] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  let navigate = useNavigate();
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    reset({ email: '', password: '' });
    setMetaTag(Message.Description);
    setTitle(Message.Title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = handleSubmit((data) => {

    setIsLoading(true);


    AuthService.Login(data.email, data.password).then(data => {
      setIsLoading(false);
      if (!data.isValidated) {
        toast.error(data.message, {
        });
      }
      else {
        toast.success(data.message, {
        });
        makeRequest();
      }
    })
  })
  async function makeRequest() {

    await delay(1000);
    var url = new URLSearchParams(search).get("helm");


    if (url !== "" && url !== undefined && url !== null) {
      var path = url.split("/", 2);

      if (path[1] === AppUrl.Vessel || path[1] === AppUrl.Company || path[1] === AppUrl.AudinenceBuilder || path[1] === AppUrl.CompanyDetailForLogin) {

        navigate(url);
      }
      else if (path[0] === AppUrl.VesselDetail) {
        navigate("/" + url);
      }
      else if (path[1] === AppUrl.Propulsion || path[1] === AppUrl.PropulsionDetail || path[1] === AppUrl.MartimeNews
        || path[1] === AppUrl.ContactUsLogin || path[1] === AppUrl.Privacy || path[1] === AppUrl.Cookiepolicy || path[1] === AppUrl.Terms
      ) {
        window.location.href = Environment.PortalLink + url;
      }
      else {
        window.location.href = Environment.PortalLink;
      }
    }
    else {
      window.location.href = Environment.PortalLink;
    }
  }
  function redirecttohome() {

    window.location.href = Environment.PortalLink;
  }
  function EmailConfirmfunc() {

    setBootstrapModal(bootstrapModal === true ? false : true);
  }
  return (
    <div className='container-fluid container-middle-align login-page'>

      {bootstrapModal === true &&
        <ConfirmAccountModal />
      }
      <Helmet>
        <title>Helm-Track</title>
        <meta name="title" content={title} />
        <meta name="description" content={metaTag} />
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-md-4 p-0 no-show">
          <div className='login-main-img'>
            <img className="img-fluid" src={LoginImg} alt="login_card" />
          </div>
        </div>
        <div className="col-lg-8 card p-0 cardWidth">
          <div className="row login-data g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width'>
                  <div className='text-center mb-4'>
                    <a onClick={redirecttohome} className="header-two-images cursor-pointer">
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </a>
                  </div>
                  <h5 className="card-title login-text">Sign In</h5>
                  <ToastContainer closeOnClick={true} />

                  <form onSubmit={onSubmit}>
                    <div className='enter-form'>
                      <div className='row'>
                        <label>Email *</label>
                        <div className="col-md-12">
                          <input type="text" name="email" className={"w-100"} autoComplete="nope" {...register("email", {
                            required: "Required", pattern: {
                              value: Validation.ValidateEmail,
                              message: Message.ValidEmail
                            }
                          })} placeholder={"Enter email"} />
                          <span className='text-danger'>{errors?.email && errors.email.message}</span>
                        </div>

                      </div>
                      <div className='row mt-3'>
                        <label>Password *</label>
                        <div className="col-md-12">
                          {/* autoComplete="new-password" */}
                          <input type="password" name="password" className={"w-100"} autoComplete="nope" {...register("password", {
                            required: "Required",
                          })} placeholder={"Enter password"} />
                          <span className='text-danger'>{errors?.password && errors.password.message}</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center sign-in-area mb-4">
                      <div >
                        <FormButton className={'btn btn-success'} IsLoading={isLoading} ButtonText="Sign In" icon={true} />
                      </div>

                      <div className="">
                        <Link to="/forgotpassword">Forgot Password?</Link>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 google-btn col-6 ">
                      <GoogleAuth />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-3 col-6 d-flex justify-content-end align-items-baseline">Don't have an account? <Link className='ms-2' to="/signup">Signup</Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 mt-1 col-12 d-flex justify-content-end align-items-baseline">Havn't confirmed email yet? <a className='ms-2 cursor-pointer' onClick={EmailConfirmfunc}>Confirm Account</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Login