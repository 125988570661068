import FooterBackground from '../../Images/header-logo.png'
import { AuthService } from '../../../../Services/Auth/AuthService';
import Cookie from "universal-cookie";
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom';
import { AppUrl } from '../../../../Helpers/AppUrl';
import { useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faGooglePlusG, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { createBrowserHistory } from "history";
import { Environment } from '../../../../Environment/Settings';
const cookie = new Cookie();
export default function Footer() {
    const history = createBrowserHistory();
    var urlPath = history.location.pathname;
    let navigate = useNavigate();
    const [user, setUser] = useState('');
    function TakeMeToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function shipowners() {
        urlPath = urlPath.split("/", 2);
        if (urlPath[1] !== "companies") {
            navigate(AppUrl.Company + AppUrl.CompanyShipOwners);
        }
        else {
            window.location.href = "/" + AppUrl.Company + AppUrl.CompanyShipOwners;
        }
    }
    function shipBuilders() {
        urlPath = urlPath.split("/", 2);
        if (urlPath[1] !== "companies") {
            navigate(AppUrl.Company + AppUrl.CompanyShipbuilders);
        }
        else {
            window.location.href = "/" + AppUrl.Company + AppUrl.CompanyShipbuilders;
        }
    }
    function TermsFunc() {
        window.location.href = Environment.Terms;
    }
    function PrivacyFunc() {
        window.location.href = Environment.Privacy;
    }
    function CookieFunc() {
        window.location.href = Environment.Cookie;
    }
    function shipPorts() {

        urlPath = urlPath.split("/", 2);
        if (urlPath[1] !== "companies") {
            navigate(AppUrl.Company + AppUrl.CompanyPorts);
        }
        else {
            window.location.href = "/" + AppUrl.Company + AppUrl.CompanyPorts;
        }
    }
    useEffect(() => {
        setUser(cookie.get("token"));
    }, [user])

    return (
        <div>
            {AuthService !== null && AuthService.CurrentUserValue ? (
                <footer className="footer">
                    <div className="container-width container-fluid">
                        <div className="row marginb">
                            <div className="col-md-4">
                                <div className="footer-logo">
                                    <img src={FooterBackground} alt="footer-img" className="img-fluid" />
                                </div>
                                {/* What started out as local Maritime weekly in 1885 in New York has evolved into the industry’s largest publishing network of print and digital media providing our clients with access to the companies that literally move our industry and the global economy. */}
                                <p>For over 140 years, New Wave Media has been the leading periodical publisher for the maritime industry. During this time, we’ve grown to an audience of over 3 million maritime professionals. </p>
                                {/* <a className="read-more" href="#">Read More <i className="fa fa-arrow-right ms-2" aria-hidden="true"></i></a> */}
                            </div>
                            <div className="col-md-8 footer-links-container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="footer-link">
                                            <p>Vessels</p>
                                            <ul className="footer-link-ul">
                                                <li><NavLink onClick={TakeMeToTop} replace to={AppUrl.FooterVessel}>Vessel Database</NavLink></li>
                                                <li><NavLink onClick={TakeMeToTop} replace to={AppUrl.FooterAudinenceBuilder}>Advance Vessel Search</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="footer-link">
                                            <p>Companies</p>
                                            <ul className="footer-link-ul">
                                                <li><span onClick={shipowners}>Ship Owners</span></li>
                                                <li><span onClick={shipBuilders}>Ship Builders</span></li>
                                                <li><span onClick={shipPorts}>Ports</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-link">
                                            <p>Maritime Sites</p>
                                            <ul className="footer-link-ul">
                                                <li><a href={AppUrl.MartimeNews}>Marine News</a></li>
                                                <li><a href={AppUrl.OffShore}>Offshore News</a></li>
                                            </ul>
                                        </div>
                                        <div className="footer-link">
                                            <p>Explore</p>
                                            <ul className="footer-link-ul">
                                                <li><a href={AppUrl.PropulsionDetail}>Marine Engines</a></li>
                                                <li><a href={AppUrl.NewsPage}>Maritime News</a></li>
                                                <li><a href={AppUrl.Advertise}>Advertise</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column justify-content-between">
                                        <div className="footer-link">
                                            <p>Contact us</p>
                                            <ul className="footer-link-ul">
                                                <li>
                                                    <FontAwesomeIcon className='me-1' icon={faEnvelope} />
                                                    <a href="mailto:support@helm-track.com"> support@helm-track.com</a>
                                                </li>
                                                <li>
                                                    <FontAwesomeIcon className='me-1' icon={faLocationDot} />
                                                    118 East 25th St., New York, NY 10010 USA</li>

                                                <li>
                                                    <FontAwesomeIcon className='me-1' icon={faPhone} />
                                                    <a href="tel:+1 561 257 0404">+1 561 257 0404</a></li>
                                                <li>

                                                    <FontAwesomeIcon className='me-1' icon={faArrowRight} />
                                                    <a href={AppUrl.ContactUs}>Contact our team</a></li>
                                            </ul>
                                        </div>
                                        <div className="social-links ">
                                            <ul className="d-flex">
                                                <li><FontAwesomeIcon icon={faFacebookF} /></li>
                                                <li><FontAwesomeIcon icon={faGooglePlusG} /></li>
                                                <li><FontAwesomeIcon icon={faTwitter} /></li>
                                                <li><FontAwesomeIcon icon={faLinkedinIn} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container-fluid container-width">
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="mb-0 me-2">©2022 New Wave Media. All right Reserved.</p>
                                <ul className="d-flex ul-color">
                                    <li><a className='cursor-pointer' onClick={TermsFunc}>Terms.</a></li>
                                    <li><a className='cursor-pointer' onClick={PrivacyFunc}>Privacy.</a></li>
                                    <li><a className='cursor-pointer' onClick={CookieFunc}>Cookie policy.</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>) : (
                <div></div>
            )
            }
        </div >
    );
}