import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
export default function FormButton({ Disable, className, IsLoading, ButtonText, icon, width, onClick }) {
    if (Disable === undefined) {
        Disable = IsLoading;
    }
    return (<button type='submit' onClick={onClick} className={className} disabled={Disable}>
        {IsLoading && <span>
            <span className="spinner-border spinner-border-sm  me-1" role="status" aria-hidden="true"></span>
            Processing...
        </span>}

        {!IsLoading &&
            <span>
                {icon ?
                    <div className='user-logo-img'>
                        <FontAwesomeIcon icon={faRightToBracket} className="" />
                    </div>
                    :
                    ""
                }
                <span className="slide-text">{ButtonText}</span>
            </span>
        }
    </button>);
}