import React, { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Validation } from '../../../../Helpers/Validation';
import { Message } from '../../../../Helpers/Message';
import { Link } from 'react-router-dom';
import { UserService } from '../../../../Services/User/UserService';
import GoogleAuth from '../../../Shared/Layout/Custom/Button/GoogleAuth/GoogleAuth';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import "./SignUp.css";
import "../../../Shared/css/style.css";
import 'react-toastify/dist/ReactToastify.css';
import FormInputDiv from '../../../Shared/Layout/Custom/FormInputDiv';
import FormInputCp from '../../../Shared/Layout/Custom/FormInputCp';
import FormInputNumber from '../../../Shared/Layout/Custom/FormInputNumber';
import FormDropDown from '../../../Shared/Layout/Custom/FormDropDown1';
import LoginImg from '../../../Shared/Images/login-img.png';
import { Environment } from '../../../../Environment/Settings';
import { ImageUrl } from "../../../../Helpers/ImageUrl";
import { Helmet } from "react-helmet";
const SignUp = () => {

  let navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [companytype, setCompanytype] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyNames, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [agreeCheck, setAgreeCheck] = useState(true);
  const [isUserRegister, setIsUserRegister] = useState(false);
  const [metaTag, setMetaTag] = useState('');
  const [title, setTitle] = useState('');
  const gender = ['Mr.', 'Mrs.', 'Miss', 'Ms.'];
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  // { mode: "all" }
  const { register, watch, setFocus, handleSubmit, formState: { errors } } = useForm();
  useEffect(() => {
    //setFocus("email");
    setMetaTag(Message.Description);
    setTitle(Message.Title);
    UserService.GetData().then(data => {
      setCountries(data.data.countries);
      setCompanytype(data.data.companyType);
    });
  }, []);



  function redirecttohome() {
    window.location.href = Environment.PortalLink;
  }
  const onSubmit = handleSubmit((data) => {
    if (data?.companyName?.trim().length === 0) {

      toast.error(Message.NameEmpty, {});
      return;
    }
    if (data?.firstName?.trim().length === 0) {

      toast.error(Message.FirstNameEmpty, {});
      return;
    }
    if (data?.lastName?.trim().length === 0) {

      toast.error(Message.LastNameEmpty, {});
      return;
    }
    data.agreeCheck = agreeCheck;
    setIsLoading(true);
    setAgreeCheck(true);
    data.userName = data.email;

    //data.companyName = data.companyName?.replace(/[@&./"',]/g, '-');
    data.IsCompany = isUserRegister;
    data.agreeCheck = agreeCheck;

    if (isUserRegister === true) {
      data.profileCompany = {
        name: data?.companyName,
        companyTypeId: Number(data?.companyTypeId), countryId: Number(data?.countryId),
        seoUrl: data?.companyName.replace(/ /gi, '-')
      };
    }
    UserService.SignUp(data).then(data => {

      if (data.isValidated) {
        setIsLoading(false);
        setAgreeCheck(false);
        toast.success(data.message, {
        });
        makeRequest()
      }
      else if (!data.isValidated) {
        setIsLoading(false);
        setAgreeCheck(false);
        toast.error(data.message, {
        });
      }
    });

  })
  async function makeRequest() {

    await delay(1000);
    navigate('/');
  }

  const handlePhoneNumber = event => {
    const result = event.target.value.replace(/[A-Za-z]/g, "");
    setPhoneNumber(result);
  };
  function AgreeToConditions() {
    setAgreeCheck(agreeCheck === true ? false : true);
  }
  function IsRegisterUser() {
    setIsUserRegister(isUserRegister === false ? true : false);
  }
  const password = useRef({});
  password.current = watch("password", "");

  return (
    <div className='container-fluid signup-middle-align signup-page'>
      <Helmet>
        <title>Helm-Track</title>
        <meta name="title" content={title} />
        <meta name="description" content={metaTag} />
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-md-4 p-0 no-show">
          <div className='login-main-img'>
            <img className="img-fluid" src={LoginImg} />
          </div>
        </div>
        <div className="col-lg-8 card p-0 cardWidth">
          <div className="row login-data g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width'>
                  <div className='text-center mb-4'>
                    <a onClick={redirecttohome} className="header-two-images cursor-pointer" >
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </a>
                  </div>
                  <h5 className="card-title login-text">Sign Up</h5>
                  <ToastContainer closeOnClick={true} />
                  <form onSubmit={onSubmit} className="google-btn">

                    <div className='row mb-3'>
                      <div className='col-md-4 small-input'>
                        <label>Title</label>
                        <div className="select w-100">
                          <select className='select-design' {...register('title'
                          )} name='title'>
                            <option value="">Select title</option>
                            {gender.map((x, y) =>
                              <option key={y}>{x}</option>)}
                          </select>
                        </div>
                        {/* <span className=' text-danger'>{errors.title?.message} </span> */}
                      </div>
                      <div className='col-md-4 small-input'>
                        <label>First Name *</label>
                        <input type="text" name="firstName" className={"w-100"} autoComplete="nope" {...register("firstName", {
                          required: Message.RequiredField
                        })} placeholder={"First Name"} />
                        <span className='text-danger'>{errors?.firstName && errors.firstName.message}</span>
                      </div>
                      <div className='col-md-4 small-input'>
                        <label>Last Name *</label>
                        <input type="text" name="lastName" className={"w-100"} autoComplete="nope" {...register("lastName", {
                          required: Message.RequiredField
                        })} placeholder={"Last Name"} />
                        <span className='text-danger'>{errors?.lastName && errors.lastName.message}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Email *</label>
                        <input type="text" name="email" className={"w-100"} autoComplete="nope" {...register("email", {
                          required: "Required", pattern: {
                            value: Validation.ValidateEmail,
                            message: Message.ValidEmail
                          }
                        })} placeholder={"Enter email"} />
                        <span className='text-danger'>{errors?.email && errors.email.message}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Phone Number </label>
                        <FormInputNumber register={register} inputClass={" w-100"} name="phoneNumber"
                          error={errors.phoneNumber?.message} type={'text'} ValidMessage={false} ValidMinimumPhoneNumber={Message.ValidMinimumPhoneNumber} ValidMaximumPhoneNumber={Message.ValidMaximumPhoneNumber}
                          placeholder={"Enter phone number"}
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Password *</label>
                        <input type="password" name="password" className={"w-100"} autoComplete="new-password" {...register("password", {
                          required: Message.RequiredField, pattern: {
                            value: Validation.ValidatePassword,
                            message: Message.ValidPassword
                          }
                        })} placeholder={"Enter password"} />
                        <span className='text-danger'>{errors?.password && errors.password.message}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Re-Enter Password *</label>
                        <FormInputCp register={register} inputClass={" w-100"} name="cPassword"
                          error={errors.cPassword?.message} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                          placeholder={"Re-enter password"} validConfirmPassword={Message.ValidConfirmPassword} watch={watch}
                          type={'Password'}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-lg-11 ms-2 col-md-11 col-11 d-flex justify-content-end ms-3 mt-3 ">

                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={isUserRegister} onClick={IsRegisterUser} />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Register as a company</label>
                        </div>
                      </div>
                    </div>
                    {
                      isUserRegister &&
                      <>
                        <div className='row mb-3'>
                          <div className='col-md-12'>
                            <label>Company Name *</label>
                          </div>
                          <input type="text" name="companyName" className={"w-100"} autoComplete="nope" {...register("companyName", {
                            required: Message.RequiredField
                          })} placeholder={"Enter company name"}
                          />
                          <span className='text-danger'>{errors?.companyName && errors.companyName.message}</span>
                        </div>
                        <div className='row mb-3'>
                          <div className='col-md-12'>
                            <label>Industry *</label>
                          </div>
                          <div className="select w-100">
                            <select className='select-design'  {...register("companyTypeId", {
                              required: Message.RequiredField
                            })} name="companyTypeId"  >
                              <option value="">Select industry</option>
                              {companytype.map(({ id, name }) => <option key={id} value={id} >{name}</option>)}
                            </select>
                          </div>
                          <span className='text-danger'>{errors?.companyTypeId && errors.companyTypeId.message}</span>
                        </div>
                        <div className='row mb-3'>
                          <div className='col-md-12'>
                            <label>Country *</label>
                          </div>
                          <div className="select w-100">
                            <select className='select-design'  {...register("countryId", {
                              required: Message.RequiredField
                            })} name="countryId"  >
                              <option value="">Select country</option>
                              {countries.map(({ id, name }) => <option key={id} value={id} >{name}</option>)}
                            </select>
                          </div>

                          <span className='text-danger'>{errors?.countryId && errors.countryId.message}</span>
                        </div>
                      </>
                    }
                    <div className='row mb-3'>
                      <div className="col-lg-11 col-md-11 col-11 register-check mt-3 ">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" onClick={AgreeToConditions} value="" id="flexCheckDefault" />
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            {Message.AgreeMessage}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="">
                        <FormButton className={'btn btn-success'} Disable={agreeCheck} IsLoading={isLoading} ButtonText="Sign Up" icon={true} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 google-btn col-6 ">
                        <GoogleAuth />
                      </div>
                      <div className="col-lg-6 col-md-6 mt-3 col-6 d-flex justify-content-end align-items-baseline">Already have an account? <Link className='ms-2' to="/">Sign In</Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default SignUp