import { Environment } from "../Environment/Settings";

export const AppUrl = {
    Home: "home",
    Login: "login",
    Company: "companies",
    CompanyShipOwners: '/owners',
    CompanyShipbuilders: '/builder',
    CompanyPorts: '/ports',
    CompanySearch: "companies/:pathParam1",
    CompanySearch1: "companies/:pathParam1/:pathParam2",
    CompanySearch2: "companies/:pathParam1/:pathParam2/:pathParam3",
    CompanyDetail: "/company",
    CompanyDetailForLogin: "company",
    Vessel: "vessels",
    FooterVessel: "/vessels",
    VesselSearch: "vessels/:pathParam1",
    VesselSearch1: "vessels/:pathParam1/:pathParam2",
    VesselSearch2: "vessels/:pathParam1/:pathParam2/:pathParam3",
    VesselSearch3: "vessels/:pathParam1/:pathParam2/:pathParam3/:pathParam4",
    VesselSearch4: "vessels/:pathParam1/:pathParam2/:pathParam3/:pathParam4/:pathParam5",
    VesselSearch5: "vessels/:pathParam1/:pathParam2/:pathParam3/:pathParam4/:pathParam5/:pathParam6",
    AddNewVessel: "/setup",
    //UpdateNewVessel: "/setup/",
    VesselDetail: "vessel",
    ViewVessel: "vessel/:SeoUrl",
    ViewCompany: "company/:SeoUrl",
    UpdateProfile: "profile",
    ChangePassword: "changepassword",
    VesselManagement: "vesselmanagement",
    Signup: "signup",
    ForgetPassword: "forgotpassword",
    UpdatePassword: "updatepassword",
    ResetPassword: "setpassword/:email",
    ConfirmAccount: "confirmaccount/:email",
    // MartimeNews: "news",
    Advertise: Environment.Advertise,
    About: "about",
    Propulsion: "propulsions",
    PropulsionDetail: Environment.Propulsion,
    OffShore: Environment.OffshoreNews,
    MartimeNews: Environment.Maritime,
    ContactUs: Environment.ContactUs,
    ContactUsLogin: "contactus",
    Terms: "terms",
    Privacy: "privacy",
    NewsPage: Environment.NewsPage,
    RouteNews: Environment.RouteNews,
    Cookiepolicy: "cookiepolicy",
    AudinenceBuilder: "audience-builder",
    FooterAudinenceBuilder: "audience-builder",
    AudinenceBuilderSearch: "audience-builder/:pathParam1",
    AudinenceBuilderSearch1: "audience-builder/:pathParam1/:pathParam2",
    AudinenceBuilderSearch2: "audience-builder/:pathParam1/:pathParam2/:pathParam3",
    AudinenceBuilderSearch3: "audience-builder/:pathParam1/:pathParam2/:pathParam3/:pathParam4",
    // Suppliers: "suppliers"
};