import { useRef } from "react";
export default function FormInputCp({ row, col, watch, type, register, inputClass, placeholder, name, error, requiredText, requiredValid, ValidMessage, validConfirmPassword }) {
    const password = useRef({});
    password.current = watch("password", "");
    return (

        <div className={col}>
            <input type={type} className={inputClass} autoComplete="nope" {...register(name, {
                required: requiredText, pattern: {
                    value: requiredValid,
                    message: ValidMessage
                }, validate: value => value === password.current || validConfirmPassword
            })} name={name} placeholder={placeholder} />
            <span className='text-danger'> {error}</span>
        </div>


    );
}